import { styled } from '@mui/system';
import Box from '@mui/material/Box';

const innerHeader = styled(Box)({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundImage: `url('/img/logo_watermark.png')`,
	backgroundRepeat: 'no-repeat',
	minHeight: '20vh',
	pt: 4,
	pb: 4,
});

export default innerHeader;
