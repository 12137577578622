// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { purple, red } from '@mui/material/colors';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import FullWidthImage from '../components/FullWidthImage';
import RightHandImage from '../components/RightHandImage';
import Sidebar from '../components/Sidebar';
import Content, { HTMLContent } from '../components/Content';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import SectionNav from '../components/Navbar-v3';
import InnerHeader from '../components/InnerHeader';
import ContactDets from '../components/ContactDets';

import logo from '../img/logo.svg';
import ProductList from '../components/ProductList';
import PilatesBall from '../img/icons/PilatesBall.png';
import BaysieLogo from '../img/icons/BaysieLogo.svg';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	zIndex: 1,
});

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: purple[200],
	padding: theme.spacing(6, 12, 12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
	flexDirection: 'column',
	p: { marginTop: 0 },
	'sup a, a.footnote-backref': {
		fontSize: '12px',
		color: theme.palette.primary.main,
		textDecoration: 'none',
	},
	'.footnotes ol': {
		fontSize: '14px',
		paddingInlineStart: '14px',
		li: { paddingBottom: '8px' },
	},
}));

// const Purple = purple[200];

// eslint-disable-next-line
export const ClinicDetailsTemplate = ({
	image,
	title,
	heading,
	subheading,
	description,
	details,
	open_hours,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<InnerHeader className='full-width-image-container margin-top-0'>
				<Typography variant='h1'>{title}</Typography>
			</InnerHeader>
			<SectionNav mb={0} />
			<FeatureOne>
				<Container>
					<Grid
						container
						item
						xs={12}
						justifyContent='center'
						mx='auto'
						textAlign='center'
						pb={6}
					>
						<Typography
							variant='h3'
							mb={1}
							dangerouslySetInnerHTML={{
								__html: description.html,
							}}
						/>
					</Grid>
					<ContactDets m={10} sx={{ m: 6, top: '250px' }} />

					<Grid container spacing={6} pt={6}>
						<Grid item xs={12} md={7}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar
											alt='Open Hours'
											src={BaysieLogo}
											sx={{
												width: 64,
												height: 64,
												display: 'inline-flex',

												padding: '6px',
												// top: '10px',
											}}
										/>
									}
									title={details.physioheading}
									titleTypographyProps={{ variant: 'h3', component: 'h3' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
								<CardContent
									sx={{ pl: 9, h3: { mb: 0 } }}
									dangerouslySetInnerHTML={{
										__html: details.physio_notes.html,
									}}
								/>
							</CardOne>
						</Grid>
						<Grid item xs={12} md={5}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar
											alt='Open Hours'
											src={PilatesBall}
											sx={{
												width: 64,
												height: 64,
												display: 'inline-flex',

												padding: '6px',
											}}
										/>
									}
									title={details.pilatesheading}
									titleTypographyProps={{ variant: 'h3', component: 'h3' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
								<CardContent
									sx={{ pl: 9, h3: { mb: 0 } }}
									dangerouslySetInnerHTML={{
										__html: details.pilates_notes.html,
									}}
								/>
							</CardOne>
						</Grid>
						{/* <Grid item xs={12} md={6}>
							<CardOne>
								<CardMedia
									sx={{ width: '30%' }}
									height='50'
									image={logo}
									alt='Paella dish'
								/>
								<CardHeader
									avatar={
										<Avatar sx={{ bgcolor: red[500] }} aria-label='recipe'>
											R
										</Avatar>
									}
									title='Check our team'
									titleTypographyProps={{ variant: 'h3' }}
									subheader='People in this world shun people for being great. For being a bright color. For standing out.'
									subheaderTypographyProps={{ variant: 'body1' }}
									direction='center'
									sx={{ width: '70%' }}
								/>
							</CardOne>
						</Grid> */}
					</Grid>
				</Container>
			</FeatureOne>
		</>
	);
};

ClinicDetailsTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ClinicDetails = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ClinicDetailsTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				description={frontmatter.description}
				details={frontmatter.details}
				open_hours={frontmatter.open_hours}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ClinicDetails.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ClinicDetails;

export const pageQuery = graphql`
	query ClinicDetailsTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "clinic-details" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				description {
					html
				}
				open_hours {
					day
					time
				}
				details {
					physio_notes {
						html
					}
					physioheading
					pilates_notes {
						html
					}
					pilatesheading
				}
			}
		}
	}
`;
