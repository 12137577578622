// copy of BlogRoll.js and updated to use JSON

import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Content, { HTMLContent } from '../components/Content';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

export const DescriptionHtml = ({ title, content, contentComponent, data }) => {
	const DescContent = contentComponent || Content;

	return <DescContent className='DescriptionHtml' content={content} />;
};

class ProductListTemplate extends React.Component {
	render() {
		const { data } = this.props;
		const { edges: products } = data.allDataJson;

		return (
			<Section
				className='columns is-multiline section'
				// sx={{ boxShadow: 12 }}
				// mx={8}
				// mt={2}
				// borderRadius={2}
				// elevation={3}
			>
				<Grid container spacing={4} sx={{ pb: 4 }}>
					{products[0].node.pc_products.map((product) => (
						<Grid item xs={12} md={4} key={product.id}>
							<Card>
								<CardContent className='is-parent column is-6' key={product.id}>
									<article className={`blog-list-item tile is-child box notification`}>
										<header>
											<div className='featured-thumbnail'>
												<PreviewCompatibleImage
													imageInfo={{
														image: product.largeImage,
														alt: `featured image thumbnail for product ${product.name}`,
													}}
												/>
											</div>
											<p className='product-meta'>
												<Link
													className='title has-text-primary is-size-4'
													to={product.name}
												>
													{product.name}
												</Link>
												<span> &bull; </span>
												<span className='subtitle is-size-5 is-block'>
													{product.name}
													image {product.name}
													<DescriptionHtml
														contentComponent={HTMLContent}
														title={product.name}
														content={product.description}
													/>
													{/* <div
														dangerouslySetInnerHTML={{ __html: product.description }}
													></div> */}
												</span>
											</p>
										</header>
										<p>
											{product.id}
											<br />
											<br />
											<Link className='button' to={product.name}>
												Keep Reading →
											</Link>
										</p>
									</article>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</Section>
		);
	}
}

ProductList.propTypes = {
	data: PropTypes.shape({
		allDataJson: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function ProductList() {
	return (
		<StaticQuery
			query={graphql`
				query ProductListQuery {
					allDataJson {
						edges {
							node {
								pc_products {
									name
									id
									description
									popletImage1
									largeImage {
										childImageSharp {
											gatsbyImageData(quality: 100, layout: CONSTRAINED)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <ProductListTemplate data={data} count={count} />}
		/>
	);
}
